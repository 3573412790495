/* eslint-disable vue/html-self-closing */
/* eslint-disable vue/html-self-closing */
<template>
  <div class="w-100 text-din-regular">
    <CeaseBanner  />
    <AppNavbarFull :company-image="companyImage" />
    <b-row class="mx-2 my-2">
      <b-button
        variant="primary"
        class="mx-0 mx-md-2"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="$router.go(-1)"
      >Go Back</b-button>
    </b-row>

    <b-overlay
      :show="isLoading"
      rounded="lg"
    >
      <div v-if="!isLoading" class="job-container d-flex flex-column flex-md-row">
        <b-col
          v-if="!$isEmpty(event_data)"
          cols="12"
          sm="12"
          md="8"
          :class="inheritedEventID ? 'md:w-100' : ''"
        >
          <b-card
            v-if="event_data.event_category !== 'group'"
            class="mb-24 px-0 px-md-2 mx-0"
          >
            <div
              v-if="
                event_data.event_category === 'single' &&
                  event_data.image_url.length > 0 &&
                  event_data.image_url !== 'default'
              "
              slot="no-body"
            >
              <img
                :src="
                  event_data.image_url == 'default'
                    ? require('@/assets/images/placeholder/no-image.jpg')
                    : event_data.image_url
                "
                alt="content-img"
                class="responsive h-96"
              >
            </div>

            <div class="mt-1 w-100">
              <span class="m-0 p-0 text-regular text-din-bold">{{ event_data.company_name }}</span>
							<div class="flex-break"/>
              <span class="m-0 p-0 text-primary text-large text-din-bold">{{ event_data.position }}</span>
							<div class="flex-break"/>
              <span class="m-0 p-0 text-regular">DTM-{{ event_data.id }}</span>
            </div>
						
						<div class="my-2 d-flex flex-wrap">
							<div class="d-flex justify-content-between mr-1">
								<feather-icon
                  v-b-tooltip.hover.top="`Job Type`"
									icon="ClockIcon"
								/>

								<h6 class="icon-header">
									{{ event_data.job_type }}
								</h6>
							</div>

							<div class="d-flex justify-content-between mr-1">
								<feather-icon
                  v-b-tooltip.hover.top="`Position Level`"
									icon="AwardIcon"
								/>

								<h6 class="icon-header">
									{{ event_data.job_level }}
								</h6>
							</div>

							<div class="d-flex justify-content-between mr-1">
								<feather-icon
                  v-b-tooltip.hover.top="`Industry`"
									icon="BriefcaseIcon"
								/>

								<h6 class="icon-header">
									{{ event_data.company_industry ? event_data.company_industry.join(", "): "NA" }}
								</h6>
							</div>
						</div>

						<div class="d-flex justify-content-end align-items-end" style="gap: 0;">
							<span class="m-0 p-0 text-large text-din-black">${{ $formatCurrency(event_data.min_salary) }} - ${{ $formatCurrency(event_data.max_salary) }}</span>
							<span class="mx-0 p-0 text-regular text-din-regular" style=""><i class="m-0 p-0">Monthly</i></span>
						</div>

						<div class="mt-2 mb-1 d-lg-flex flex-gap-md d-block">
							<div>{{ event_data.number_of_applications ? event_data.number_of_applications : 0 }} Applications</div>
							<div>Posted on {{ event_data.cleaned_created_on }}</div>
							<div>Closing on {{ event_data.cleaned_closing_on }}</div>
						</div>

            <div
              v-if="['mcf', 'efc'].includes(event_data.from_source)"
              class="d-flex flex-gap-sm align-items-center"
            >
              <span
                class="text-warning text-regular text-din-bold"
              >Source: {{ event_data.from_source === 'mcf' ? 'MyCareersFuture': 'eFinancialCareers' }}
              </span>
              <font-awesome-icon
                v-b-tooltip.hover.top="mcf_message"
                icon="question-circle"
                class="text-warning"
              />
            </div>

            <b-row
              v-if="!inheritedEventID"
              class="mt-2 pt-0 w-100 mx-0 px-0"
            >
              <b-col
                cols="12"
                md="6"
                class="w-100 m-0 px-0 pr-md-1 pl-md-0 py-0"
              >
                <b-button
                  variant="warning"
                  icon-pack="feather"
                  icon="icon-link-2"
                  class="w-100 shadow-md"
                  @click="onCopy(event_data.id)"
                >Share Job</b-button>
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="mt-2 mt-md-0 w-100 m-0 px-0 pl-md-1 pr-md-0 py-0"
              >
                <b-button
                  v-if="
                    checkIDRegistered(registered, event_data.id)
                  "
                  ref="join"
                  class="w-100"
                  variant="success"
                  icon-pack="feather"
                  icon="icon-check"
                  @click="joinEvent(event_data, $event)"
                >Applied</b-button>
                <b-button
                  v-else-if="event_data.external"
                  ref="join"
                  class="w-100 shadow-md"
                  variant="primary"
                  icon-pack="feather"
                  icon="icon-check"
                  @click="fireRedirect(event_data, $event)"
                >
                  Apply Now</b-button>

                <div
                  v-else
                  class="vx-col w-100 md:w-1/2"
                >
                  <b-button
                    ref="join"
										class="w-100 shadow-md"
                    variant="primary"
                    @click="joinEvent(event_data, $event)"
                  >Apply Now</b-button>
                </div>
              </b-col>
            </b-row>

						<hr class="my-2">

            <hr v-if="event_data.note">
            <b-row v-if="event_data.note">
              <p
                style="font-weight: 600; font-size: 14px;white-space: pre-line;"
                class="mx-2 mt-1"
              >
                <span>
                  Note:
                </span>
                <br>
                <span>
                  {{ event_data.note }}
                </span>

              </p>
            </b-row>

						<div>
							<h6
								v-if="['mcf', 'efc'].includes(event_data.from_source)"
								class="mt-1 text-regular text-din-bold"
							>Job Description and Requirements
							</h6>
							<h6
								v-else
								class="mt-1 mx-0 px-0 text-primary font-weight-bold"
                style="font-weight: bold;"
							>Job Description and Requirements
								<!-- Job Description -->
							</h6>
							<div class="mt-1 mx-0 mx-md-2 event-description-format" style="white-space: normal; list-style-position: inside;" v-html="event_data.description"></div>
						</div>

            <hr>
            <h6
              v-if="
                event_data.applicant_description &&
                  event_data.applicant_description.length > 1
              "
              class="my-1"
            >
              Brief Description Of Candidate
            </h6>
            <div
              v-if="
                event_data.applicant_description &&
                  event_data.applicant_description.length > 1
              "
              class="d-flex"
            >
              <p class="ml-1">
                {{ event_data.applicant_description }}
              </p>
            </div>

            <hr
              v-if="
                event_data.applicant_description &&
                  event_data.applicant_description.length > 1
              "
            >
						<div v-if="false">
							<h6 class="my-1">
								Job Type
							</h6>
							<div class="d-flex mx-auto">
								<b-badge
									pill
									variant="primary"
									class="ml-1 mr-1"
								>{{
									capitalize(event_data.job_type)
								}}</b-badge>
							</div>

							<h6 class="my-1">
								Employment Type
							</h6>
							<div class="d-flex mx-auto">
								<div
									v-for="(tag, index) in event_data.employment_type"
									:key="index"
									class="ml-1"
								>
									<b-badge
										pill
										color="primary"
										class="mr-1"
									>
										{{ capitalize(tag) }}
									</b-badge>
								</div>
							</div>

							<hr>
							<div class="d-flex justify-content-between w-100">
								<h6 class="mt-1">
									Working Days:
								</h6>
								<p class="mt-1 w-50 text-right">
									{{ event_data.working_days }}
								</p>
							</div>

							<hr>
							<div class="d-flex justify-content-between w-100">
								<h6 class="mt-1 w-50">
									Working Hours:
								</h6>
								<p class="mt-1 w-50 text-right">
									{{ event_data.working_time }}
								</p>
							</div>

							<hr>
							<div class="d-flex justify-content-between w-100">
								<h6 class="mt-1 w-50">
									Working Location:
								</h6>
								<p
									class="mt-1 w-50 text-right"
								>
									{{
										event_data.working_location_address ==
											event_data.working_location_postal_code
											? `${event_data.working_location_state}, ${event_data.working_location_country},
																	${event_data.working_location_postal_code}`
											: `${event_data.working_location_address}, ${event_data.working_location_state},
																	${event_data.working_location_country}, ${event_data.working_location_postal_code}`
									}}

									<!-- <span
										v-if="event_data.remote"
										class="font-bold ml-2"
									>(Working from home allowed)</span> -->
									<span
										v-if="event_data.working_location_type === 'remote_only' || event_data.working_location_type === 'hybrid'"
										class="font-bold ml-2"
									>(Working from home allowed)</span>
								</p>
							</div>

							<hr>
							<div class="d-flex justify-content-between w-100">
								<h6 class="mt-1 w-50">
									Salary :
								</h6>
								<h6
									v-if="event_data.min_salary == event_data.max_salary"
									class="mt-1 w-50 text-right"
								>
									{{
										event_data.max_salary === 1 ? "Undisclosed" : event_data.max_salary
									}}
									{{
										event_data.max_salary === 1
											? ""
											: cleanCycles(event_data.salary_cycle)
									}}
								</h6>

								<h6
									v-else-if="event_data.max_salary === 'To be discussed later'"
									class="mt-1 w-50 text-right"
								>
									{{ event_data.max_salary }}
								</h6>

								<h6
									v-else
									class="mt-1 w-50 text-right"
								>
									{{ $$formatCurrency(event_data.min_salary) }} - {{ $formatCurrency(event_data.max_salary) }}
									{{ cleanCycles(event_data.salary_cycle) }}
								</h6>
							</div>

							<!-- <hr v-if="event_data.cleaned_start_date"> -->
								<!-- v-if="event_data.cleaned_start_date" -->
							<div
								v-if="false"
								class="d-flex justify-content-between w-100"
							>
								<h6 class="mt-1 w-50">
									Event Period:
								</h6>

								<p class="font-semibold w-50 text-right">
									{{ event_data.cleaned_start_date }} to
									{{ event_data.cleaned_end_date }}
								</p>
							</div>

							<!-- <hr v-if="event_data.cleaned_start_time"> -->
								<!-- v-if="event_data.cleaned_start_time" -->
							<div
								v-if="false"
								class="d-flex justify-content-between w-100"
							>
								<h6 class="mt-1 w-50">
									Event Time:
								</h6>
								<p class="font-semibold w-50 text-right">
									{{ event_data.cleaned_start_time }} to
									{{ event_data.cleaned_end_time }}
								</p>
							</div>

							<hr>
							<b-row class="d-flex mx-auto">
								<div
									v-for="(tag, index) in event_data.tags"
									:key="index"
								>
									<b-badge
										pill
										variant="dark"
									>
										{{ tag }}
									</b-badge>
								</div>
							</b-row>

						</div>
          </b-card>

          <b-card
            class="mb-24 p-0 p-md-2 mx-0"
            v-if="false"
          >
						<p class="text-primary font-weignt-bolder">Tell employers what skills you have</p>
						<p class="mt-1">The more skills you have, the better your job match.</p>

						<div class="my-2">
							<p class="mb-0 pb-0">15% skills matched</p>

							<div class="d-flex w-100" style="margin-top: .3rem; gap: .6rem;">
								<b-progress
									v-for="bar in splittedProgressBar"
									:key="bar.key"
									class="splitted-progress-bar"
									:value="bar.progress"
									:max="100"
									animated
								/>
							</div>
						</div>

						<div class="my-2">
							<p>Skills you have added</p>
							<div class="w-100 d-flex flex-wrap" style="gap: .2rem;">
								<div
									v-for="(skill, key) in addedSkills"
									:key="skill.key" 
									class="selected skill-badge d-flex align-items-center"
								>
                  <feather-icon
                    icon="CheckIcon"
										size="18"
                  />
									{{ skill.text }}
								</div>
							</div>
						</div>

						<div class="mt-2">
							<p>Add these skills if you have them</p>
							<div class="w-100 d-flex flex-wrap" style="gap: .2rem;">
								<div
									v-for="(skill, key) in exploreSkils"
									:key="skill.key" 
									class="skill-badge d-flex align-items-center"
								>
                  <feather-icon
                    icon="PlusIcon"
										size="18"
                  />
									{{ skill.text }}
								</div>
							</div>
						</div>

						<hr class="my-2">

						<div class="d-flex">
							<div v-if="false"></div>
							<div class="w-100">
								<p class="m-0 p-0 font-weight-bolder">Grow your job opportunities</p>
								<p class="m-0 p-0 ">Explore some relevant courses from MySkillsFuture.gov.sg on:</p>

								<div class="d-flex flex-wrap p-1 w-100">
									<a
										v-for="(skill, key) in exploreSkils"
										:key="skill.key" 
										class="a-explore_skill" :href="skill.href"
									>{{ skill.text }}</a>
								</div>
							</div>
						</div>
					</b-card>

        </b-col>

        <b-col
          v-if="!inheritedEventID"
          cols="12"
          sm="12"
          md="4"
        >
					<b-card v-if="false" class="p-1">
						<p class="mx-0 px-0 text-primary font-weight-bolder">Brought To You By : </p>
						<div class="d-flex w-100">
							<div v-if="false">
								<b-avatar
									square
									class="mb-6 block sm:w-24 sm:h-20 md:w-24 md:h-24"
									size="40px"
									:src="
										event_data.company_image == 'default'
											? require('@/assets/images/placeholder/no-image.jpg')
											: event_data.company_image
									"
								/>
							</div>
							<div class="">
								<div>
									<h5 class="sm:mt-2 md:mt-2 text-left font-weight-bolder">{{ event_data.company_name }} </h5>
								</div>
								<div>
									<p class="m-0 p-0 sm:mt-2 md:mt-2 text-left italic">{{ event_data.company_summary }} </p>
								</div>
							</div>
						</div>
					</b-card>

          <h5 class="mb-2">
            Suggested Jobs
          </h5>
          <div
            v-if="!$isEmpty(suggestions) && suggestions.length > 0"
            class="h-auto"
          >
            <b-card
              v-for="(item, index) in suggestions"
              :key="index"
              class="mt-2 shadow-lg cursor-pointer"
              @click="suggestedLink(item.id)"
            >
              <span
                v-if="item.event_category === 'join'"
                class="m-0 p-0 text-regular text-din-bold italic"
              >
                <em>{{ item.company_name }}</em>
              </span>
              <span
                v-else
                class="m-0 p-0 text-regular text-din-bold italic"
              >{{ item.company_name }}</span>

							<h4 class="m-0 p-0 text-primary text-din-bold text-large">{{item.position}}</h4>

							<div class="d-flex flex-wrap" style="gap: .3rem; margin-block: .7rem;">
								<div
									v-for="(tag, index) in item.employment_type"
									:key="index"
								>
									<b-badge
										pill
										color="warning"
									>{{ cleanerTag(tag) }}
									</b-badge>
								</div>
							</div>
              <div
                class="d-flex flex-wrap"
                :class="{'flex-wrap': isMobile || isTablet}"
              >
                <div v-if="false" class="d-flex">
                  <font-awesome-icon
                    icon="map-marker-alt"
                    class=""
                  />
                  <h6 class="icon-header">
                    <!-- {{ cleanLocation(item.work_location) }} -->

                    {{
                      cleanLocation(
                        item.work_location,
                        item.location_zone
                      )
                    }}
                  </h6>
                </div>

								<div class="d-flex justify-content-between mr-1">
									<!-- <font-awesome-icon icon="fa-solid fa-clock" /> -->
									<!-- <font-awesome-icon :icon="['fas', 'clock']" /> -->
									<div class="d-flex">
										<feather-icon
                      v-b-tooltip.hover.top="`Job Type`"
											style="margin-top: .05rem;"
											icon="ClockIcon"
										/>
									</div>

									<h6 class="icon-header">{{ item.job_type }}</h6>
								</div>

								<div class="d-flex justify-content-between mr-1">
									<div class="d-flex">
										<feather-icon
                      v-b-tooltip.hover.top="`Position Level`"
											style="margin-top: .05rem;"
											icon="AwardIcon"
										/>
									</div>

									<h6 class="icon-header">{{ item.job_level }}</h6>
								</div>

								<div class="d-flex justify-content-between mr-1">
									<div class="d-flex">
										<feather-icon
                      v-b-tooltip.hover.top="`Industry`"
											style="margin-top: .05rem;"
											icon="BriefcaseIcon"
										/>
									</div>

									<h6 class="icon-header">{{ item.company_industry ? item.company_industry.join(", "): "NA" }}</h6>
								</div>


                <div class="d-flex">
                  <!-- <font-awesome-icon icon="dollar-sign" /> -->
									<font-awesome-icon
                    v-b-tooltip.hover.top="`Salary`"
										style="margin-top: .1rem;"
										icon="money-bill" 
									/>
                  <h6
                    v-if="item.min_salary == item.max_salary"
                    class="icon-header"
                  >{{ item.max_salary === 1 ? "Undisclosed" : $formatCurrency(item.max_salary) }}
                    {{
                      item.max_salary === 1 ? "" : cleanCycles(item.salary_cycle)
                    }}
                  </h6>

                  <h6
                    v-else-if="item.max_salary === 'To be discussed later'"
                    class="icon-header"
                  >{{ item.max_salary }}</h6>

                  <h6
                    v-else
                    class="icon-header"
                  >${{ $formatCurrency(item.min_salary) }} - ${{ $formatCurrency(item.max_salary) }}
                    {{ cleanCycles(item.salary_cycle) }}
                  </h6>
                </div>
              </div>
            </b-card>
          </div>

          <div v-else>
            <b-card>
              <h5>No suggestions available for this position</h5>
            </b-card>
          </div>
        </b-col>

        <!-- <b-col
          v-else-if="event_data.event_status === 'closed'"
          cols="12"
          sm="12"
          md="8"
          class="text-center py-4"
        >
          <b-card>
            <h2 class="mb-1 w-00">
              Event Has Been Closed
            </h2>
            <div class="mt-2">
              <b-button
                variant="primary"
                class="mx-1 mb-1 btn-sm-block"
                :to="{name: 'listings-page'}"
              >Go To Listing Page</b-button>
            </div>
          </b-card>
        </b-col> -->
      </div>
    </b-overlay>

    <b-row>
      <div class="">
        <b-modal
          ref="popupReg"
          v-model="popupReg"
          centered
          hide-footer
          size="lg"
        >
          <div class="w-100">
            <h6 class="mb-6">
              You will require a HeadHunters HQ account to proceed with the
              application.
            </h6>
            <b-tabs justified>
              <b-tab title="Login">
                <login-page
                  class="w-100"
                  which-page="listings"
                  :event-id="this.$router.currentRoute.path"
                  @user-logged-in="afterUserLoggedIn"
                />
              </b-tab>
              <b-tab title="Registration">
                <RegistrationForm
                  class="w-100"
                  :extra-data="popup_questions"
                  :event-id="event_id"
                  which-page="listings"
                  account-type="Applicant"
                  @user-registered="afterUserRegistered"
                />
              </b-tab>
            </b-tabs>
          </div>
        </b-modal>
      </div>
    </b-row>
    <!-- Quiz Modal -->
    <JobPopup
      :activate-popup="popup_quiz"
      :job-questions="popup_questions"
      :save-nric="save_nric"
      :all-applications="registered"
      @application-form="checkForm"
      @application-update="applicationUpdate"
    />
    <ProfileCompletionForm
      :activate-popup="popupProfileQuestions"
      @open-job-form="getQuestions"
      @open-completion-form="activateTypeForm"
    />

    <div class="container-fluid">
      <AppFooter class="w-100" />
    </div>
    

  </div>
</template>

<script>
import { upperFirst } from 'lodash'
import platform from 'platform'
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BModal,
  VBModal,
  BBadge,
  BAvatar,
  BOverlay,
  BTabs,
  VBTooltip,
  BTab,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { allIndustries } from '@/constants/datastore'
import AppNavbarFull from '@/layouts/components/AppNavbarFull.vue'
import AppFooter from '@/layouts/components/AppFooter.vue'

import JobPopup from '@/components/JobPopup.vue'
import LoginPage from '@/views/authentication/login/LoginForm.vue'
import RegistrationForm from '@/views/authentication/registration/RegistrationForm.vue'
import { onResizeMixins } from '@/components/mixins/onResizeMixins'
import { listingsMixin } from './mixins/listingsMixins'

import designCouncilImage from '@/assets/images/logo/designsingapore-logo-white.png'

export default {
  components: {
    JobPopup,
    LoginPage,
    RegistrationForm,
    AppNavbarFull,
    AppFooter,
    BCard,
    BButton,
    BRow,
    BCol,
    BModal,
    BBadge,
    BAvatar,
    BOverlay,
    BTabs,
    BTab,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  mixins: [listingsMixin, onResizeMixins],
  props: {
    inheritedEventID: { type: String, default: '' },
  },
  data() {
    return {
      companyImage: '',
      specified_event: {},
      jsonld: {},
      popup_image: '',
      book: {
        text: 'Register',
        color: 'primary',
      },
      event_data: {},
      event_id: '',
      fullname: '',
      email_address: '',
      show_inside: true,
      popupActive: false,
      image_url: 'default',
      event_exist: false,
      action: 'insert',
      current_item: {},
      popupReg: false,
      item_id: '',
      companies: [],
      registered: [],
      description_manager: {},
      inside_data: {},
      all_event_records: {},
      event_groups: [],

      popup_event: {},

      select_event_type: '',
      event_types: [
        { text: 'Single events', value: 'single' },
        { text: 'Group events', value: 'group' },
      ],
      select_tags: [],
      select_date: [],
      selected_job_type: '',
      selected_industry: '',
      industries: [],
      which_resume: 'existing_resume',
      categories: [
        { text: 'Administration, business and management', value: 1 },
        { text: 'Alternative therapies', value: 2 },
        { text: 'Animals, land and environment', value: 3 },
        { text: 'Computing and ICT', value: 4 },
        { text: 'Construction and building', value: 5 },
        { text: 'Design, arts and crafts', value: 6 },
        { text: 'Education and training', value: 7 },
        { text: 'Engineering', value: 8 },
        { text: 'Facilities and property services', value: 9 },
        { text: 'Financial services', value: 10 },
        { text: 'Garage services', value: 11 },
        { text: 'Hairdressing and beauty', value: 12 },
        { text: 'Healthcare', value: 13 },
        { text: 'Heritage, culture and libraries', value: 14 },
        { text: 'Hospitality, catering and tourism', value: 15 },
        { text: 'Legal and court services', value: 16 },
        { text: 'Manufacturing and production', value: 17 },
        { text: 'Performing arts and media', value: 18 },
        { text: 'Retail and customer services', value: 19 },
        { text: 'Science, mathematics and statistics', value: 20 },
        { text: 'Security, uniformed and protective services', value: 21 },
        { text: 'Social sciences and religion', value: 22 },
        { text: 'Sport and leisure', value: 23 },
        { text: 'Transport, distribution and logistics', value: 24 },
      ],
      job_types: [
        { text: 'Part-time', value: 'parttime' },
        { text: 'Full-time', value: 'fulltime' },
        { text: 'Internship/Traineeship', value: 'internship_traineeship' },
        { text: 'Freelance', value: 'freelance' },
        { text: 'Permanent', value: 'permanent' },
        { text: 'Contract', value: 'contract' },
        { text: 'Flexi-work', value: 'flexiwork' },
        { text: 'Temporary', value: 'temporary' },
      ],

      selected_location: '',
      selected_event_title: '',
      all_locations: [],
      unfiltered_data: [],
      application_bypass: false,
      suggestions: [],
      aff_id: '',
      applied: false,

      mcf_message:
        'All information on jobs is published in good faith. DSG does not make any warranties about the completeness, reliability and accuracy of this information. From the jobs portal, you can visit other websites by following hyperlinks on the job ads to external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove any dead/unrelated/inappropriate content or links.',
			addedSkills: {
				'HTML': { text: 'HTML', href: ''},
				'CSS': { text: 'CSS', href: ''},
			},
			exploreSkils: {
				'MongoDB': { text: 'MongoDB', href: ''},
				'.NET': { text: '.NET', href: ''},
				'Scrum': { text: 'Scrum', href: ''},
				'JavaScript': { text: 'JavaScript', href: ''},
				'MySQL': { text: 'MySQL', href: ''},
				'MySQL': { text: 'MySQL', href: ''},
				'SDLC': { text: 'SDLC', href: ''},
				'Python': { text: 'Python', href: ''},
				'Java': { text: 'Java', href: ''},
				'C#': { text: 'c#', href: ''},
				'API': { text: 'API', href: ''},
				'C#': { text: 'C#', href: ''},
				'Databases': { text: 'Databases', href: ''},
				'C++': { text: 'C++', href: ''},
			},
			splittedProgressBar: [
				{ progress: 100, },
				{ progress: 50, },
				{ progress: 0, },
				{ progress: 0, },
				{ progress: 0, },
				{ progress: 0, },
				{ progress: 0, },
				{ progress: 0, },
				{ progress: 0, },
				{ progress: 0, },
			]
    }
  },

  watch: {
    event_id(val) {},
    selected_event_title: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },
    select_tags: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },
    select_date: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },
    select_company: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },
    selected_location: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },
    selected_job_type: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },

    selected_industry: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },

    select_event_type: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },
    select_date: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },
    popup_quiz: {
      handler(val, oldVal) {
      },
    },
    deep: true,
  },
  created() {
    const { token } = this.$store.state.auth.ActiveUser || {}

    if (this.inheritedEventID) {
      this.event_id = this.inheritedEventID
    } else {
      const event_id = this.$route.params.eventid
      this.event_id = event_id
    }

    const { query } = this.$route

    if (query.aff_id) {
      this.aff_id = query.aff_id
      this.updateAff()
    }

    this.email_address = this.$store.state.auth.ActiveUser
      ? this.$store.state.auth.ActiveUser.email
      : ''
    if (this.event_id) {
      this.industries = allIndustries
      this.$http.defaults.headers.common["Authorization"] = `Token ${token}`;
      this.isLoading = true
      this.getEvent(this.event_id)

      // this.populateMockData()
      // this.isLoading = false
    }
  },
  methods: {
    populateMockData() {
			this.companyImage = designCouncilImage
      this.event_data = {
        description: `Our company deals with interior furnishings products and our customers are creative individuals. We brand ourselves as a creative company that offers aesthetical solutions to any interiors with our wide ranges of products from around the world.
The Sales Associate is responsible for achieving sales instore/outdoor, delivering service and operations excellence. He/she also engages in innovation and productivity initiatives for the store as well as outdoor sales. In order to drive seamless customer experience across channels, he/she handles order fulfilment processes for customers in store and outdoor.  He/She operates in a fast-paced, diverse and customer-centric store environment. As the first point-of-contact in store, he/she serves as the ambassador of the organisation.  He/she is a highly motivated and service-oriented individual. He/She communicates well and thrives in a team environment.
Specific Responsibilities:
Mid Career Personnel/ Trainee to be involved in:
1. Achieving Sales target set by company
2. Maintaining the integrity of company brand and direction
3. Engaging in building a good customer relationship with existing and new clientale base
4. Works hand in hand with Sales Manager/Director and team to achieve the sales target in store and outdoor.
5. Handles fulfilment processes of sales obtained.

Competencies:
1. Achieving Sales
2. Achieving Service Excellence to existing and new clients
3. Delivering Operation Excellence
4. Executing Customers Order Fulfilment
5. Engage in innovation and productivity initiatives`,
        requirements: `Our company deals with interior furnishings products and our customers are creative individuals. We brand ourselves as a creative company that offers aesthetical solutions to any interiors with our wide ranges of products from around the world.
The Sales Associate is responsible for achieving sales instore/outdoor, delivering service and operations excellence. He/she also engages in innovation and productivity initiatives for the store as well as outdoor sales. In order to drive seamless customer experience across channels, he/she handles order fulfilment processes for customers in store and outdoor.  He/She operates in a fast-paced, diverse and customer-centric store environment. As the first point-of-contact in store, he/she serves as the ambassador of the organisation.  He/she is a highly motivated and service-oriented individual. He/She communicates well and thrives in a team environment.
Specific Responsibilities:
Mid Career Personnel/ Trainee to be involved in:
1. Achieving Sales target set by company
2. Maintaining the integrity of company brand and direction
3. Engaging in building a good customer relationship with existing and new clientale base
4. Works hand in hand with Sales Manager/Director and team to achieve the sales target in store and outdoor.
5. Handles fulfilment processes of sales obtained.

Competencies:
1. Achieving Sales
2. Achieving Service Excellence to existing and new clients
3. Delivering Operation Excellence
4. Executing Customers Order Fulfilment
5. Engage in innovation and productivity initiatives`,


				job_id: '013fbcaf-ec3f-447b-aceb-78385affa0da',
				id: '013fbcaf-ec3f-447b-aceb-78385affa0da',
				position: 'Sales Associate',
				employer_name: 'CHROMA FURNISHING PTE. LTD.',
        job_type: 'Full-Time',
        job_level: "Manager",
        employment_type: ['Permanent'],
        employer_industry: ['Staffing and Recruiting'],
        working_days: '6 Days',
        working_time: 'Shift Hours',
        working_location_address: 'Oxley Bizhub 2, 62 Ubi Road 1',
        working_location_state: 'Singapore',
        working_location_country: 'Singapore',
        working_location_postal_code: '408734',
        min_salary: 'S$1',
        max_salary: 'S$1,000',
        start_date: "1st May 2023",
        end_date: "19th Jun 2023",
        start_time: "01:00:00",
        end_time: "15:00:00",
        cleaned_start_date: "1st May 2023",
        cleaned_end_date: "19th Jun 2023",
        cleaned_start_time: "01:00",
        cleaned_end_time: "15:00",
        
        company_uen_no: '201721612D',
        uen_no: '201721612D',
        company_name: 'HEADHUNTERS HQ PTE. LTD.',
        company_status: true,
        company_summary: 'HeadHunters HQ is a HR Tech marketplace for Talent Acquisition/Recruitment Solutions that seeks to connect Recruitment Agencies, Direct Employers and Job Seekers together. The marketplace houses integrated modular solutions and with each of these solutions designed and engineered to streamline existing processes and empower organisations with the cutting edge to hire faster, better and the right talent.',
      }
      this.suggestions = [
				{
					"job_id": "90b6dabd-eace-4b34-b737-7716b2e94dbf",
					"position": "Brochure Designer",
					"min_salary": 3500,
					"max_salary": 4500,
					"salary_cycle": "Monthly",
					"industry": [],
					"featured": false,
					"interview_type": "virtual",
					"job_functions": null,
					"employment_type": [
						"internship_traineeship",
						"permanent"
					],
					"industry_1": [],
					"job_type": 'Full-Time',
					"job_level": "Middle Management",
					"employer_industry": ['Logistics'],
					"working_location_address": '',
					"working_location_state": "Singapore",
					"working_location_country": "Singapore",
					"working_location_postal_code": null,
					"source_name": null,
					"application_url": null,
					"event_id": "cc04238b-e18f-4d74-adaf-598d1a99b6ba",
					"event_name": "Design Job",
					"start_date": "1st May 2023",
					"end_date": "19th Jun 2023",
					"start_time": "01:00:00",
					"end_time": "15:00:00",
					"event_banner": "event-images/bbe073da-782b-4105-90e1-4dd1b638d734_1684378578.jpeg",
					"job_questions": [
						{
							"id": "0c587090-351e-42f0-9796-ba9330303c73",
							"job_id": "90b6dabd-eace-4b34-b737-7716b2e94dbf",
							"question": "Event screening question?"
						},
						{
							"id": "4c687829-5ccb-4875-8df3-937d4eebf643",
							"job_id": "90b6dabd-eace-4b34-b737-7716b2e94dbf",
							"question": "Have you ever create a promotional video?"
						}
					],
					"event_questions": [
						{
							"event_id": "cc04238b-e18f-4d74-adaf-598d1a99b6ba",
							"id": "0f85e306-f65d-4a6a-8e11-3fcb2dd1d38a",
							"question": "Event screening question?"
						}
					],
					"company_name": "Express Delivery Inc",
					"company_image": "company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg",
					"organiser_name": "Employment and Employability Inc",
					"organiser_image": "company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg",
					"job_applications": null,
					"last_postal_code": null,
					"location_zone": null,
					"company": "Express Delivery Inc",
					"external": false,
					"note": null,
					"event_category": "join",
					"work_location": {
						"address": null,
						"country": "Singapore",
						"postal_code": null
					},
					"cleaned_start_date": "1st May",
					"cleaned_end_date": "19th Jun 2023",
					"cleaned_start_time": "01:00 AM",
					"cleaned_end_time": "03:00 PM",
					"id": "90b6dabd-eace-4b34-b737-7716b2e94dbf",
				},

				{
					"job_id": "90b6dabd-eace-4b34-b737-7716bxw94dbf",
					"position": "Videographer",
					"min_salary": 3800,
					"max_salary": 4800,
					"salary_cycle": "Monthly",
					"industry": [],
					"featured": false,
					"interview_type": "virtual",
					"job_functions": null,
					"employment_type": [
						"internship_traineeship",
						"permanent"
					],
					"industry_1": [],
					"job_type": 'Full-Time',
					"job_level": "Senior Management",
					"employer_industry": ['Logistics'],
					"working_location_address": '',
					"working_location_state": "Singapore",
					"working_location_country": "Singapore",
					"working_location_postal_code": null,
					"source_name": null,
					"application_url": null,
					"event_id": "cc04238b-e18f-4d74-adaf-598d1a99b6ba",
					"event_name": "Design Job",
					"start_date": "1st May 2023",
					"end_date": "19th Jun 2023",
					"start_time": "01:00:00",
					"end_time": "15:00:00",
					"event_banner": "event-images/bbe073da-782b-4105-90e1-4dd1b638d734_1684378578.jpeg",
					"job_questions": [
						{
							"id": "0c587090-351e-42f0-9796-ba9330303c73",
							"job_id": "90b6dabd-eace-4b34-b737-7716b2e94dbf",
							"question": "Event screening question?"
						},
						{
							"id": "4c687829-5ccb-4875-8df3-937d4eebf643",
							"job_id": "90b6dabd-eace-4b34-b737-7716b2e94dbf",
							"question": "Have you ever create a promotional video?"
						}
					],
					"event_questions": [
						{
							"event_id": "cc04238b-e18f-4d74-adaf-598d1a99b6ba",
							"id": "0f85e306-f65d-4a6a-8e11-3fcb2dd1d38a",
							"question": "Event screening question?"
						}
					],
					"company_name": "Express Delivery Inc",
					"company_image": "company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg",
					"organiser_name": "Employment and Employability Inc",
					"organiser_image": "company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg",
					"job_applications": null,
					"last_postal_code": null,
					"location_zone": null,
					"company": "Express Delivery Inc",
					"external": false,
					"note": null,
					"event_category": "join",
					"work_location": {
						"address": null,
						"country": "Singapore",
						"postal_code": null
					},
					"cleaned_start_date": "1st May",
					"cleaned_end_date": "19th Jun 2023",
					"cleaned_start_time": "01:00 AM",
					"cleaned_end_time": "03:00 PM",
					"id": "90b6dabd-eace-4b34-b737-7716b2e94dbf"
				},
      ]
    },
    checkForm(val = false) {
      this.popup_quiz = val
    },
    applicationUpdate(value) {
      this.registered = value
    },
    activateTypeForm(val) {
      this.popupProfileQuestions = val
    },
    getQuestions(val) {
      this.popup_quiz = true
      this.popup_questions = val.questions
      this.save_nric = val.save_nric
    },

    cleanerTag(tag) {
      if (!this.$isEmpty(tag)) {
        const splitted = tag.split('_')
        const new_splitted = []
        splitted.forEach(word => {
          new_splitted.push(this.$capitalizeString(word))
        });

        return new_splitted.join(' ')
      }
    },
    cleanLocation(location, zone) {
      let { address } = location
      const { country } = location
      address = !this.$isEmpty(address) ? address.replace(`${country} `, '') : address

      let currentZone = zone
      if (currentZone === undefined || currentZone === null) {
        currentZone = 'NA'
      }
      return `${currentZone} (${location.country} ${location.postal_code})`.replace(' null', '').replace('null', '')
    },
    // cleanLocation(location) {
    //   let { address } = location || { address: '' }
    //   const { country } = location || { country: '' }
    //   let location_string = ''
    //   if (!this.$isEmpty(address)) {
    //     address = address.replace(`${country} `, '')
    //     location_string += `${address}, `
    //   }
    //   location_string += country

    //   return location_string
    // },
    fireRedirect(item) {
      window.open(item.redirection_url, '_blank')
    },
    updateAff() {
      const all_data = { aff_id: this.aff_id, platform }
      this.$http
        .put('/api/aff-stats', { data: all_data })
        .then(response => {})
        .catch(error => {})
    },
    goBack() {
      this.$router.go(-1)
    },
    capitalize(item) {
      if (item) {
        item = item.replace('_', '/')
        return upperFirst(item)
      }
      return ''
    },
    onCopy(event_id) {
      this.copyTextToClipboard(`${window.location.origin}/jobs/${event_id}`)
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Job Link Alert',
            icon: 'AlertCircleIcon',
            text: 'Job link copied successfully',
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        () => {},
        err => {},
      )
    },
    suggestedLink(event_id) {
      const job_link = `${window.location.origin}/jobs/${event_id}`
      window.open(job_link, '_blank')
    },
    cleanCycles(dirty_cycle) {
      if (dirty_cycle) {
        dirty_cycle = dirty_cycle.toLowerCase()
        const equiv_cycles = {
          yearly: 'per Year',
          monthly: 'per Month',
          weekly: 'per Week',
          daily: 'per Day',
          hourly: 'per Hourly',
        }

        return equiv_cycles[dirty_cycle]
      }
    },
    cleanedJobDetails(dirty_text) {
      if (!this.$isEmpty(dirty_text)) {
        let cleaned_text = ''
        try {
          cleaned_text = dirty_text
            .replace('Job Description', '')
            .replace('Job Requirements', '')
        } catch (error) {
          cleaned_text = dirty_text
        }

        const result = cleaned_text.split('\n').filter(e => e.length > 3)
        return result
      }
      return ''
    },
    filtered_data() {
      const filtered_inside_jobs = []

      for (const job of this.unfiltered_data) {
        if (this.filter_events(job.details)) {
          filtered_inside_jobs.push(job)
          this.description_manager = job.details
        }
      }

      if (filtered_inside_jobs.length === 0) {
        this.description_manager = {}
      } else {
        this.description_manager = filtered_inside_jobs[0].details
      }

      this.inside_data = filtered_inside_jobs
    },
    filter_events(data) {
      let new_events = false

      const location = this.selected_location.length > 0
        ? this.selected_location.includes(data.work_location)
        : true

      const tags = this.select_tags.length > 0
        ? this.select_tags.some(v => data.tags.includes(v))
        : true

      const event_type = this.select_event_type.length > 0
        ? this.select_event_type.includes(data.event_category)
        : true

      const job_type = this.selected_job_type.length > 0
        ? this.selected_job_type.some(v => data.preferences.includes(v))
        : true

      const date = this.select_date.length > 0
        ? this.select_date.includes(data.start_date)
        : true

      const title = this.selected_event_title.length > 0
        ? data.position
          .toLowerCase()
          .includes(this.selected_event_title.toLowerCase())
        : true

      if (tags && location && event_type && job_type && date && title) {
        new_events = true
      }

      return new_events
      // this.events_data = new_events;
    },

    restoreCreds() {
      const { token } = this.$store.state.auth.ActiveUser || {}
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
    },

    changeDescription(details) {
      this.description_manager = details
      this.$forceUpdate()
    },

    alreadyRegistered() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Job Application Update',
            icon: 'AlertCircleIcon',
            text: 'You have already applied for this job.',
            variant: 'warning',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },

    bookEvent() {
      if (this.book.text === 'Applied') {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Job Application Update',
              icon: 'AlertCircleIcon',
              text: 'You have already applied for this job.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      } else if (this.email_address.length > 4 && this.fullname.length > 3) {
        const all_data = {
          event_id: this.event_id,
          email_address: this.email_address,
          fullname: this.fullname,
          approved: false,
          interviewed: false,
          company: this.$store.state.auth.ActiveUser.company_name,
        }
        this.$http
          .post('/api/book', { data: all_data })
          .then(response => {
            const { success } = response.data
            if (success) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Registration Success',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              // this.book.text = "Registered";
              // this.book.color = "success";
              this.popupReg = false
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Registration Failed',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {})
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Invalid data',
              icon: 'AlertCircleIcon',
              text: 'Please enter a valid email address or fullname',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    getEvent(job_id) {
      this.restoreCreds()
      this.$http
        .get(`/api/job/${job_id}`)
        .then(response => {
          if (response.data.success) {
            const item = response.data.output
            this.registered = response.data.registered
            this.event_data = item
            
            // const isRegistered = this.checkIDRegistered(this.registered)

            this.specified_event = item
            this.suggestions = response.data.suggestions

            this.jsonld = {
              '@context': 'https://schema.org/',
              '@type': 'JobPosting',
              title: item.position,
              description: `${item.description}\n${item.requirements}`,
              identifier: {
                '@type': 'PropertyValue',
                name: item.company,
                value: item._id,
              },
              datePosted: item.created_on,
              validThrough: item.expires_on,
              applicantLocationRequirements: {
                '@type': 'Country',
                name: 'Singapore',
              },
              jobLocation: {
                '@type': 'Place',
                address: {
                  '@type': 'PostalAddress',
                  addressCountry: 'SG',
                },
              },
              baseSalary: {
                '@type': 'MonetaryAmount',
                currency: 'SGD',
                value: {
                  '@type': 'QuantitativeValue',
                  value: item.salary,
                  unitText: 'MONTH',
                },
              },
              jobLocationType: item.remote ? 'TELECOMMUTE' : '',
              employmentType:
                item.type === 'fulltime' ? 'FULL_TIME' : 'PART_TIME',
              hiringOrganization: {
                '@type': 'Organization',
                name: item.company,
                sameAs: 'http://dashboard.headhuntershq.com/jobs',
                logo: item.company_image,
              },
            }

            // if (this.$route.query.quiz === "true") {
            // if(!isRegistered){
            //  this.joinEvent(item)
            // }
            // }
            // this.$store.commit("UPDATE_COMPANY_IMAGE", this.specified_event.organiser_image);
            this.companyImage = this.specified_event.organiser_image
            // this.$emit('image-update', this.specified_event.organiser_image)
          }
          this.isLoading = false
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },
    cleanerIntervals(event_details) {
      if (event_details.start_date === event_details.end_date) {
        return `Happening on ${event_details.cleaned_start_date}, ${event_details.cleaned_start_time}`
      }
      return `Happening from ${event_details.cleaned_start_date}, ${event_details.cleaned_start_time} to ${event_details.cleaned_end_date}, ${event_details.cleaned_end_time}`
    },
    checkIDRegistered(registered, job_id) {
      const id = this.$store.state.auth.ActiveUser
        ? this.$store.state.auth.ActiveUser.id
        : null

      if (registered) {
        if (Object.keys(registered).includes(job_id)) {
          return registered[job_id].includes(id)
        }
        return false
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";

div {
  white-space: pre-wrap;
}

.job-container {
	margin: 0 2rem;
}

.bullet-points {
  list-style-type: square;
}

.image-size {
  max-height: 540px;
  max-width: 1200px;
  object-fit: contain;
}

.icon-header {
  margin-left: 5px;
  margin-top: -1px;
}

.skill-badge {
	border: 1px solid $primary;
	border-radius: 25px;
	height: 2rem;
	padding-inline: .5rem;
	color: $primary;
	cursor: pointer;
	
}
.selected {
	background: $primary;
	color: $white;
}
.a-explore_skill {
	width: 50%;
	color: $primary;
	text-decoration: underline;
	min-width: 50%;
	max-width: 50%;
}
.splitted-progress-bar {
	width: 10%;
	height: .3rem;
}

 // Extra small (xs)
@media (max-width: 575.98px) {
	.job-container {
		margin: 0;
	}
}

// Small (sm)
@media (min-width: 576px) and (max-width: 767.98px) {
	.job-container {
		margin: 0;
	}
}

// Medium (md)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large (lg)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Extra large (xl)
@media (min-width: 1200px) {
}

// Extra extra large (xxl)
@media (min-width: 1400px) {
}

</style>
