var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{staticClass:"bg-dark text-din-black text-regular",staticStyle:{"height":"5rem","width":"100%"},attrs:{"sticky":"","justified":"","fill":""}},[_c('b-navbar-brand',{attrs:{"align":"start"}},[_c('div',{},[_c('a',{staticClass:"cursor-pointer",attrs:{"target":"_blank","href":"https://designsingapore.org/"}},[_c('img',{staticClass:"w-auto object-scale-down",staticStyle:{"height":"3rem","margin-top":"1.5rem","margin-bottom":"1.5rem"},attrs:{"src":_vm.latestCompanyImage}})])])]),_c('b-navbar-nav',{staticClass:"ml-auto",attrs:{"align":"end"}},[_c('div',{staticClass:"d-md-flex d-none flex-row text-din-bold align-items-center"},[_c('router-link',{class:((_vm.$route.name === 'listings-event' ? 'text-primary cursor-default' : 'text-light') + " mr-2"),staticStyle:{"width":"50px"},attrs:{"to":{name: 'listings-event' }}},[_vm._v(" Home ")]),_c('router-link',{class:((_vm.$route.name === 'listings-page' ? 'text-primary cursor-default' : 'text-light') + " mr-2"),staticStyle:{"width":"100px"},attrs:{"to":{name: 'listings-page' }}},[_vm._v(" Design Jobs ")]),(_vm.$route.name === 'listings-event')?_c('a',{staticClass:"text-light mr-2",staticStyle:{"width":"90px"},on:{"click":function($event){return _vm.$scrollTo('highlights')}}},[_vm._v(" Highlights ")]):_vm._e(),_c('a',{staticClass:"text-light mr-2",staticStyle:{"width":"90px"},attrs:{"href":"mailto:talent@designsingapore.org"}},[_vm._v(" Contact Us ")]),(_vm.isLoggedIn)?_c('div',{staticClass:"mr-2",staticStyle:{"min-width":"100px"}},[_c('router-link',{staticClass:"text-white",attrs:{"to":{
            name: _vm.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.APPLICANT
              ? 'manage-applications'
              : 'home'
          }}},[_c('b-button',{staticClass:"w-full shadow-lg text-din-black text-regular",staticStyle:{"width":"auto"},attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.APPLICANT ? 'Dashboard' : 'Dashboard')+" ")])],1)],1):_c('div',{staticClass:"mr-2"},[_c('b-button',{staticClass:"w-full shadow-lg text-din-black text-regular",attrs:{"variant":"primary"},on:{"click":_vm.activateLogin}},[_vm._v("Sign In")])],1)],1),(true)?_c('div',{staticClass:"d-md-none d-block text-din-bold"},[_c('b-dropdown',{staticClass:"mobile-nav-dropdown text-right",attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":"","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',[_c('feather-icon',{attrs:{"icon":"MenuIcon","size":"32"}})],1)]},proxy:true}],null,false,2246249417)},[_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{staticClass:"text-dark",attrs:{"to":{name: 'listings-event' }}},[_vm._v(" Home ")])],1),_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{staticClass:"text-dark",attrs:{"to":{name: 'listings-page' }}},[_vm._v(" Design Jobs ")])],1),_c('b-dropdown-item',{attrs:{"target":"_blank","href":"https://designsingapore.org/contact-us/"}},[_c('a',{staticClass:"text-dark",attrs:{"href":"mailto:talent@designsingapore.org"}},[_vm._v(" Contact Us ")])]),(!_vm.isLoggedIn)?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{staticClass:"text-dark",attrs:{"to":{name: 'auth-login'}}},[_vm._v(" Sign In ")])],1):_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{staticClass:"text-dark",attrs:{"to":{
              name: _vm.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.APPLICANT
                ? 'manage-applications'
                : 'home'
            }}},[_vm._v(" "+_vm._s(_vm.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.APPLICANT ? 'Dashboard' : 'Dashboard')+" ")])],1)],1)],1):_vm._e()]),_c('router-view',{on:{"login-state":_vm.updateUserLoggedIn}}),_c('b-modal',{attrs:{"centered":"","size":"lg","hide-footer":"","title":""},model:{value:(_vm.login_form),callback:function ($$v) {_vm.login_form=$$v},expression:"login_form"}},[_c('b-row',{staticClass:"m-0 p-0 text-din-regular",attrs:{"cols":"12"}},[_c('b-tabs',{staticClass:"w-100 ml-1",attrs:{"align":"center","fill":""},model:{value:(_vm.active_tab),callback:function ($$v) {_vm.active_tab=$$v},expression:"active_tab"}},[_c('b-tab',{staticClass:"w-100",attrs:{"title":"Sign In"}},[_c('LoginForm',{staticClass:"w-100",attrs:{"which-page":"listings","event-id":this.$router.currentRoute.path},on:{"user-logged-in":_vm.afterUserLoggedIn}})],1),_c('b-tab',{staticClass:"w-100",attrs:{"title":"Registration"}},[_c('RegistrationForm',{staticClass:"w-100",attrs:{"which-page":"listings","account-type":"Applicant"},on:{"user-registered":_vm.afterUserLoggedIn}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }